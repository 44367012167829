import {Service} from '../../service/Service';

export class EventService extends Service {

  static entity = 'event';

  static getUser(token: string): Promise<any> {
    return this.axios.get(`${this.entity}`, {params: {token}}).then(res => res.data);
  }

  static setUser(id: string, data: any): Promise<any> {
    return this.axios.put(`${this.entity}/user/${id}`, data).then(res => res.data);
  }

  static mock(code: string): Promise<any> {
    return this.axios.post(`${this.entity}/mock`, {code}).then(res => res.data);
  }

}

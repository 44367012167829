import {createRoot} from 'react-dom/client';
import {HashRouter, Route, Routes} from 'react-router-dom';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/layout/layout.scss';
import './App.scss';

import ScrollToTop from './ScrollToTop';

import {AppLoading} from './AppLoading';
import {AppToast} from './AppToast';
import {AppConfirmDialog} from './AppConfirmDialog';

import App from './app';
import Login from './pages/login';
import {Event} from './pages/event';
import {Mock} from './pages/event/mock';

document.documentElement.style.fontSize = '14px';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <ScrollToTop>
      <Routes>
        <Route path="login" element={<Login/>}/>
        <Route path="event/*" element={<Event/>}/>
        <Route path="mock" element={<Mock/>}/>
        <Route path="*" element={<App/>}/>
      </Routes>
      <AppLoading/>
      <AppToast/>
      <AppConfirmDialog/>
    </ScrollToTop>
  </HashRouter>
);
import {useEffect} from 'react';

import {UsersService as Service} from './UsersService';

import {useDataTable} from '../../shared/hooks/DataTable';
import {useDialogCrup} from '../../shared/hooks/DialogCrup';

import {ActionsService} from '../AppBreadCrumb';
import {ToastService} from '../../AppToast';

const roles = [
  {value: 'admin', label: 'Admin'},
  {value: 'crup', label: 'Chỉnh sửa'},
  {value: 'delete', label: 'Xóa'},
  {value: 'export', label: 'Kết xuất dữ liệu'}
];

export const Users = () => {

  const header = 'Tài khoản';
  const dataKey = 'user_login_id';

  useEffect(() => {
    ActionsService.setActions([
      {label: 'Tạo tài khoản', icon: 'pi pi-fw pi-plus', className: 'p-action-primary', command: create}
    ]);
    return () => {
      ActionsService.setActions(null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'username', header, width: 170, matchMode: 'contains'},
      {field: 'role_type_name', header: 'Vai trò', width: 200},
      {field: 'full_name', header: 'Tên người dùng', minWidth: 200, matchMode: 'contains'},
      {field: 'description', header: 'Ghi chú', minWidth: 150, matchMode: 'contains'},
      {field: 'enabled', header: 'Kích hoạt', width: 100, sortable: false, dataType: 'boolean'},
      {field: 'created_date', header: 'Ngày tạo', width: 160, dataType: 'date-time'}
    ],
    indexColumnWidth: 40,
    actionColumnWidth: 80,
    getList: lazyParams => {
      return Service.getList(lazyParams);
    },
    initActions: items => {
      if (items) {
        items.forEach(item => {

          item.enabled = item.status !== 'Disabled';
          if (item.role_type) {
            item.role_type = item.role_type.split(',');

            const role_type_name = [];
            item.role_type.forEach(rt => {
              roles.forEach(r => {
                if (r.value === rt) {
                  role_type_name.push(r.label);
                }
              });
            });
            item.role_type_name = role_type_name.join(', ');
          }

          item.actions = [
            {icon: 'pi pi-pencil', title: 'Chỉnh sửa', command: () => update(item)}
          ];

          const actionMenus = [
            {label: 'Khôi phục mật khẩu', icon: 'pi pi-replay', command: () => setPassword(item)}
          ];
          if (item.enabled) {
            actionMenus.push({label: 'Khóa tài khoản', icon: 'pi pi-lock', command: () => setStatus(item, 'Disabled')})
          } else {
            actionMenus.push({label: 'Mở khóa tài khoản', icon: 'pi pi-lock-open', command: () => setStatus(item, 'Enabled')});
          }
          item.actionMenus = actionMenus;
        });
      }
    }
  });

  const {render: renderDialogCrup, create, update} = useDialogCrup({
    header,
    dataKey,
    width: '60rem',
    fields: [
      {field: 'username', header, required: true, className: 'md:col-12', disabled: 'onlyUpdate'},
      {field: 'role_type', header: 'Vai trò', required: true, type: 'MultiSelect', MultiSelectProps: {options: roles}, className: 'md:col-12'},
      {field: 'password', header: 'Mật khẩu', required: true, className: 'md:col-6', disabled: 'onlyUpdate'},
      {field: 'full_name', header: 'Tên người dùng', required: true, className: 'md:col-6'},
      {field: 'description', header: 'Ghi chú', className: 'md:col-12'}
    ],
    createItem: item => {
      if (item.role_type) {
        item.role_type = item.role_type.join(',');
      }
      return Service.create(item);
    },
    updateItem: (id, item) => {
      if (item.role_type) {
        item.role_type = item.role_type.join(',');
      }
      return Service.update(id, item);
    },
    reloadLazyData: () => {
      reloadLazyData();
    }
  });

  const {render: renderDialogSetPassword, update: setPassword} = useDialogCrup({
    header: '!Khôi phục mật khẩu',
    dataKey,
    width: '40rem',
    fields: [
      {field: 'password', header: 'Mật khẩu', required: true, className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.setPassword(id, item.password);
    },
    reloadLazyData: () => {
      reloadLazyData();
    }
  });

  const setStatus = (item: any, status: string) => {
    Service.setStatus(item[dataKey], status).then(() => {
      ToastService.success();
      reloadLazyData();
    });
  }

  return (
    <div className="card pb-0">
      {renderDataTable()}
      {renderDialogCrup()}
      {renderDialogSetPassword()}
    </div>
  );
}
import axios from 'axios';

import {LoadingService} from '../AppLoading';

import {baseURL} from '../config';
import {ToastService} from '../AppToast';
import {translate} from '../shared/language';

const _axios = axios.create({
  baseURL
});

_axios.interceptors.request.use(config => {
  LoadingService.start();

  return config;
}, error => {
  return Promise.reject(error);
});
_axios.interceptors.response.use(response => {
  LoadingService.stop();
  return response;
}, error => {

  LoadingService.stop();

  if (error && error.response && error.response.status === 401) {
    window.location.hash = '/login';
  } else if (error.response && error.response.data && error.response.data.errors) {
    if (typeof error.response.data.errors === 'string') {
      ToastService.error(error.response.data.errors);
    } else if (error.response.data.errors.constraint) {
      ToastService.error(error.response.data.errors.constraint);
    }
  } else if (error.response && error.response.data && typeof error.response.data.errorCode === 'string') {
    ToastService.error(translate(error.response.data.errorCode));
  } else {
    ToastService.error();
  }
  return Promise.reject(error);
});

export default _axios;

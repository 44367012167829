import {EntityService} from '../../service/EntityService';

export class LotteriesService extends EntityService {

  static entity = 'lotteries';

  static getCustomers(id: string, lazyLoadEvent: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/customers?lazyLoadEvent=${lazyLoadEvent}`).then(res => res.data);
  }

  static award(id: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${id}/award`, data).then(res => res.data);
  }

  static disable(id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/disable`).then(res => res.data);
  }

  static enable(id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/enable`).then(res => res.data);
  }

  static resetPublishSlot(id: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/reset-publish_slot`).then(res => res.data);
  }

  static getWinners(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/winners`).then(res => res.data);
  }

  static getTheme(id: string): Promise<any> {
    return this.axios.get(`${this.entity}/${id}/theme`).then(res => res.data);
  }

  static setTheme(id: string, data: any): Promise<any> {
    return this.axios.post(`${this.entity}/${id}/theme`, data).then(res => res.data);
  }

}

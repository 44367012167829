import {useState} from 'react';
import {Link} from 'react-router-dom';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';

import {AuthService} from '../../service/AuthService';

import {AppStorage} from '../../service/UserStorage';
import {ToastService} from '../../AppToast';

export default function Login() {

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = event => {
    if (!submitting) {
      setSubmitting(true);

      AuthService.login(username, password).then(data => {
        if (data && data.username) {
          AppStorage.set('user_login', data);
          window.location.hash = '/';
        } else {
          ToastService.error();
        }
      }).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  return (
    <div className="layout-wrapper layout-overlay layout-theme-light">

      <div className="layout-topbar">
        <Link to="/" className="layout-topbar-logo">
          <img src="/coop-smile/CoopSmile-Logo.webp" alt="CoopSmile" style={{height: 'auto'}}/>
        </Link>
      </div>

      <div className="layout-main-container login-page">
        <div className="layout-main">
          <div className="surface-ground py-8 flex align-items-center justify-content-center">

            <div className="surface-card p-4 shadow-2 border-round" style={{width: '35rem'}}>
              <div className="text-center mb-5">
                <div className="text-900 text-3xl font-medium mb-3">Đăng nhập</div>
                <span className="text-600 font-medium line-height-3">Coop Smile Gamification Marketing</span>
              </div>

              <form onSubmit={handleSubmit}>
                <label htmlFor="username" className="block text-900 font-medium mb-2">Tài khoản</label>
                <InputText id="username" className="w-full mb-3" value={username} onChange={e => setUsername(e.target.value)} required/>

                <label htmlFor="password" className="block text-900 font-medium mb-2">Mật khẩu</label>
                <Password id="password" className="w-full mb-3" value={password} onChange={e => setPassword(e.target.value)} toggleMask feedback={false} required/>

                <Button label="Đăng nhập" icon="pi pi-user" className="w-full" loading={submitting}/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
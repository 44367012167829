import {useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import {ToastService} from '../../AppToast';

import {useForm, Field} from './Form';

interface DialogCrupProps {
  header: string
  dataKey?: string
  width: string
  fields: Array<Field>

  createItem?(item: any): Promise<any>

  updateItem?(id: string, item: any): Promise<any>

  reloadLazyData?(data?: any): void

  layout?: string
  formClassName?: string

  submitLabel?: string

  checkResponseError?(data?: any): string
}

export const useDialogCrup = (props: DialogCrupProps) => {

  const {header, dataKey, width, fields, createItem, updateItem, reloadLazyData, layout, formClassName} = props;

  const [idUpdate, setIdUpdate] = useState(null);
  const [idClone, setIdClone] = useState(null);
  const [idView, setIdView] = useState(null);
  const [display, setDisplay] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {render: renderForm, reset, setValue, valid: formValid, getValue, getRawValue} = useForm({
    fields,
    idUpdate,
    layout,
    formClassName,
    readOnlyInput: !!idView
  });

  const create = (defaultValue?: any) => {
    reset();
    if (defaultValue) {
      setValue(defaultValue);
    }
    setIdUpdate(null);
    setIdClone(null);
    setDisplay(true);
  }

  const update = (item: any) => {
    item.isUpdateValue = true;
    reset();
    setValue(item);
    setIdUpdate(item[dataKey]);
    setIdClone(null);
    setDisplay(true);
  }

  const clone = (item: any) => {
    reset();
    setValue(item);
    setIdUpdate(null);
    setIdClone(item[dataKey]);
    setDisplay(true);
  }

  const view = (item: any) => {
    reset();
    setValue(item);
    setIdView(item);
    setDisplay(true);
  }

  const handleSubmit = () => {
    if (!submitting && formValid()) {
      setSubmitting(true);

      const data = getValue();

      delete data.isUpdateValue;

      let promise;
      if (idUpdate) {
        promise = updateItem(idUpdate, data);
      } else {
        promise = createItem(data);
      }

      promise.then(data => {
        const error = props.checkResponseError && props.checkResponseError(data);
        if (error) {
          ToastService.error(error);
        } else {
          setDisplay(false);
          if (reloadLazyData) {
            ToastService.success();
            reloadLazyData(data);
          }
        }
      }).finally(() => {
        setSubmitting(false);
      });
    }
  }

  const render = () => {
    let _header;
    if (header && header.substring(0, 1) === '!') {
      _header = header.substring(1, header.length);
    } else {
      _header = `Thêm mới ${header}`;
      if (idClone) {
        _header = `Sao chép ${header}`;
      }
      if (idUpdate) {
        _header = `Chỉnh sửa ${header}`;
      }
    }

    const footer = (
      <div>
        {!idView &&
					<Button label={props.submitLabel || 'Lưu'} icon="pi pi-check" onClick={handleSubmit} loading={submitting} className="p-button-sm mr-2"/>
        }
        <Button label="Đóng" icon="pi pi-times" onClick={() => setDisplay(false)} className="p-button-outlined p-button-secondary p-button-sm"/>
      </div>
    );

    return (
      <Dialog header={_header} footer={footer} visible={display} style={{width}} position="top" onHide={() => setDisplay(false)}>

        {renderForm()}

      </Dialog>
    );
  }

  return {render, create, update, clone, view, getValue, setValue, getRawValue};
}

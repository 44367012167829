import {EntityService} from '../../service/EntityService';

export class UsersService extends EntityService {

  static entity = 'users';

  static setPassword(id: string, password: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/setPassword`, {password}).then(res => res.data);
  }

  static setStatus(id: string, status: string): Promise<any> {
    return this.axios.patch(`${this.entity}/${id}/status`, {status}).then(res => res.data);
  }

}

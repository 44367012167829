import {useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';

import {LotteriesService} from '../../lotteries/LotteriesService';
import {CustomersService as Service} from './CustomersService';

import {useDataTable} from '../../../shared/hooks/DataTable';
import {useDialogCrup} from '../../../shared/hooks/DialogCrup';

import {ActionsService} from '../../AppBreadCrumb';
import {ToastService} from '../../../AppToast';

export const Customers = ({me}) => {

  const header = 'Khách hàng';
  const dataKey = 'marketing_event_user_id';

  const [marketingEvent, setMarketingEvent] = useState(null);
  const [filteredMarketingEvents, setFilteredMarketingEvents] = useState(null);

  const [requiredParams, setRequiredParams] = useState(null);

  useEffect(() => {
    if (marketingEvent && marketingEvent.value) {

      setRequiredParams({marketing_event_id: {value: marketingEvent.value, matchMode: 'equals'}});

      if (me.role_type?.includes('admin') || me.role_type?.includes('export')) {
        ActionsService.setActions([
          {label: 'Kết xuất dữ liệu', icon: 'pi pi-fw pi-download', className: 'p-action-primary', command: exportExcel}
        ]);
      }
    }
    return () => {
      ActionsService.setActions(null);
    }
  }, [marketingEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchMarketingEvent = event => {
    const _event = {
      filters: {
        event_name: {value: event.query, matchMode: 'contains'}
      }
    };
    LotteriesService.getList(JSON.stringify(_event)).then(data => {
      setFilteredMarketingEvents(data.items.map(item => {
        return {value: item.marketing_event_id, label: item.event_name}
      }));
    });
  }

  const exportExcel = () => {
    if (marketingEvent && marketingEvent.value) {
      window.open(`${LotteriesService.baseURL}/report/${marketingEvent.value}/customers/excel`)
    } else {
      ToastService.error('Bạn chưa chọn chương trình');
    }
  }

  const {render: renderDataTable, reloadLazyData} = useDataTable({
    dataKey,
    columns: [
      {field: 'user_full_name', header: 'Tên khách hàng', minWidth: 200, matchMode: 'contains'},
      {field: 'event_user_code', header: 'Mã quay số', width: 170, matchMode: 'contains'},
      {field: 'phone_number', header: 'Số điện thoại', width: 150, matchMode: 'contains'},
      {field: 'created_date', header: 'Ngày đăng ký', width: 160, dataType: 'date-time'},
      {field: 'sms_sent', header: 'Gửi SMS', title: 'sms_sent_detail', width: 100, dataType: 'boolean', filterType: 'boolean', matchMode: 'equals'}
    ],
    indexColumnWidth: 40,
    actionColumnWidth: 80,
    getList: lazyParams => {
      return Service.getList(lazyParams);
    },
    requiredParams,
    initActions: items => {
      if (items) {
        items.forEach(item => {
          if (me.role_type?.includes('admin') || me.role_type?.includes('crup')) {
            item.actions = [
              {icon: 'pi pi-pencil', title: 'Chỉnh sửa', command: () => update(item)},
              {icon: 'pi pi-send', className: 'p-button-warning', title: 'Gửi lại SMS', command: () => sendSms(item)},
            ];
          }
        });
      }
    }
  });

  const {render: renderDialogCrup, update} = useDialogCrup({
    header,
    dataKey,
    width: '40rem',
    fields: [
      {field: 'user_full_name', header: 'Tên khách hàng', className: 'md:col-12'},
      {field: 'phone_number', header: 'Số điện thoại', className: 'md:col-12'}
    ],
    updateItem: (id, item) => {
      return Service.update(id, item);
    },
    reloadLazyData: () => {
      reloadLazyData();
    }
  });

  const sendSms = item => {
    Service.sendSms(item[dataKey]).then(() => {
      reloadLazyData();
    });
  }

  return (
    <div className="card pb-0">
      <div className="p-fluid grid">
        <div className="col-6">
          <AutoComplete value={marketingEvent} onChange={e => setMarketingEvent(e.value)}
                        field="label" suggestions={filteredMarketingEvents} completeMethod={searchMarketingEvent} dropdown={true}/>
        </div>
      </div>
      {renderDataTable()}
      {renderDialogCrup()}
    </div>
  );
}
import {Fragment, useEffect, useRef, useState} from 'react';

import moment from 'moment';

import {OverlayPanel} from 'primereact/overlaypanel';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';

import {FormatDisplay} from '../format';

const periods = [
  {value: 'TODAY', label: 'Hôm nay'},
  {value: 'THISWEEK', label: 'Tuần này'},
  {value: 'THISMONTH', label: 'Tháng này'},
  {value: 'THISFISCALQUARTER', label: 'Quý này'},
  {value: 'THISFISCALYEAR', label: 'Năm nay'},
  {value: 'FIRSTSIXMONTH', label: '6 tháng đầu năm'},
  {value: 'LASTSIXMONTH', label: '6 tháng cuối năm'},
  {value: 'JAN', label: 'Tháng 1'},
  {value: 'FEB', label: 'Tháng 2'},
  {value: 'MAR', label: 'Tháng 3'},
  {value: 'APR', label: 'Tháng 4'},
  {value: 'MAY', label: 'Tháng 5'},
  {value: 'JUN', label: 'Tháng 6'},
  {value: 'JUL', label: 'Tháng 7'},
  {value: 'AUG', label: 'Tháng 8'},
  {value: 'SEP', label: 'Tháng 9'},
  {value: 'OCT', label: 'Tháng 10'},
  {value: 'NOV', label: 'Tháng 11'},
  {value: 'DEC', label: 'Tháng 12'},
  {value: 'FISCALQUARTER1', label: 'Quý I'},
  {value: 'FISCALQUARTER2', label: 'Quý II'},
  {value: 'FISCALQUARTER3', label: 'Quý III'},
  {value: 'FISCALQUARTER4', label: 'Quý IV'},
  {value: 'LASTWEEK', label: 'Tuần trước'},
  {value: 'LASTMONTH', label: 'Tháng trước'},
  {value: 'LASTFISCALQUARTER', label: 'Quý trước'},
  {value: 'LASTFISCALYEAR', label: 'Năm trước'}
];

export const Period = ({value, onChange}) => {

  const op = useRef(null);

  const [label, setLabel] = useState(null);
  const [period, setPeriod] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    if (value && value.length === 2) {
      setLabel(FormatDisplay.date(value[0]) + ' - ' + FormatDisplay.date(value[1]));
    } else {
      setLabel('-');
    }
  }, [value]);

  useEffect(() => {
    if (period) {
      const periodValue = getPeriodValue(period);
      setFromDate(periodValue.fromDate);
      setToDate(periodValue.toDate);
    }
  }, [period]);

  useEffect(() => {
    if (fromDate && toDate) {
      onChange({value: [fromDate, toDate]});
    } else {
      onChange({value: null});
    }
  }, [fromDate, toDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPeriodValue = (period?: string): any => {
    let fromDate = new Date(), toDate = new Date();

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const quarters = ['FISCALQUARTER1', 'FISCALQUARTER2', 'FISCALQUARTER3', 'FISCALQUARTER4'];
    if (period && months.indexOf(period) >= 0) {
      fromDate = moment().month(months.indexOf(period)).startOf('month').toDate();
      toDate = moment().month(months.indexOf(period)).endOf('month').toDate();
    } else if (period && quarters.indexOf(period) >= 0) {
      fromDate = moment().quarter(quarters.indexOf(period) + 1).startOf('quarter').toDate();
      toDate = moment().quarter(quarters.indexOf(period) + 1).endOf('quarter').toDate();
    } else {
      switch (period) {
        case 'TODAY':
          fromDate = moment().startOf('day').toDate();
          toDate = moment().endOf('day').toDate();
          break;
        case 'THISWEEK':
          fromDate = moment().startOf('isoWeek').toDate();
          toDate = moment().endOf('isoWeek').toDate();
          break;
        case 'THISWEEKTODATE':
          fromDate = moment().startOf('isoWeek').toDate();
          break;
        case 'THISMONTH':
          fromDate = moment().startOf('month').toDate();
          toDate = moment().endOf('month').toDate();
          break;
        case 'THISMONTHTODATE':
          fromDate = moment().startOf('month').toDate();
          break;
        case 'THISFISCALQUARTER':
          fromDate = moment().startOf('quarter').toDate();
          toDate = moment().endOf('quarter').toDate();
          break;
        case 'THISFISCALQUARTERTODATE':
          fromDate = moment().startOf('quarter').toDate();
          break;
        case 'THISFISCALYEAR':
          fromDate = moment().startOf('year').toDate();
          toDate = moment().endOf('year').toDate();
          break;
        case 'THISFISCALYEARTODATE':
          fromDate = moment().startOf('year').toDate();
          break;
        case 'FIRSTSIXMONTH':
          fromDate = moment().startOf('year').toDate();
          toDate = moment().month(5).endOf('month').toDate();
          break;
        case 'LASTSIXMONTH':
          fromDate = moment().month(6).startOf('month').toDate();
          toDate = moment().endOf('year').toDate();
          break;
        case 'YESTERDAY':
          fromDate = moment().subtract(1, 'day').startOf('day').toDate();
          toDate = moment().subtract(1, 'day').endOf('day').toDate();
          break;
        case 'LASTWEEK':
          fromDate = moment().subtract(1, 'week').startOf('isoWeek').toDate();
          toDate = moment().subtract(1, 'week').endOf('isoWeek').toDate();
          break;
        case 'LASTMONTH':
          fromDate = moment().subtract(1, 'month').startOf('month').toDate();
          toDate = moment().subtract(1, 'month').endOf('month').toDate();
          break;
        case 'LASTFISCALQUARTER':
          fromDate = moment().subtract(1, 'quarter').startOf('quarter').toDate();
          toDate = moment().subtract(1, 'quarter').endOf('quarter').toDate();
          break;
        case 'LASTFISCALYEAR':
          fromDate = moment().subtract(1, 'year').startOf('year').toDate();
          toDate = moment().subtract(1, 'year').endOf('year').toDate();
          break;
        case 'NEXTWEEK':
          fromDate = moment().add(1, 'week').startOf('isoWeek').toDate();
          toDate = moment().add(1, 'week').endOf('isoWeek').toDate();
          break;
        case 'NEXTMONTH':
          fromDate = moment().add(1, 'month').startOf('month').toDate();
          toDate = moment().add(1, 'month').endOf('month').toDate();
          break;
        case 'NEXTFISCALQUARTER':
          fromDate = moment().add(1, 'quarter').startOf('quarter').toDate();
          toDate = moment().add(1, 'quarter').endOf('quarter').toDate();
          break;
        case 'NEXTFISCALYEAR':
          fromDate = moment().add(1, 'year').startOf('year').toDate();
          toDate = moment().add(1, 'year').endOf('year').toDate();
          break;
      }
    }
    return {fromDate, toDate};
  }

  return (
    <Fragment>
      <div className="p-dropdown p-component p-inputwrapper" onClick={e => op.current.toggle(e)}>
        <span className="p-dropdown-label p-inputtext">{label}</span>
        <div className="p-dropdown-trigger">
          <span className="p-dropdown-trigger-icon p-clickable pi pi-chevron-down"></span>
        </div>
      </div>
      <OverlayPanel ref={op} style={{width: '450px'}}>
        <div className="p-fluid">
          <div className="field">
            <label htmlFor="fromDate">Thời gian</label>
            <Dropdown value={period} options={periods} onChange={e => setPeriod(e.value)} filter/>
          </div>
          <div className="field">
            <label htmlFor="fromDate">Từ ngày</label>
            <Calendar id="fromDate" value={fromDate} onChange={e => setFromDate(e.value)} dateFormat="dd/mm/yy"/>
          </div>
          <div className="field">
            <label htmlFor="toDate">Đến ngày</label>
            <Calendar id="toDate" value={toDate} onChange={e => setToDate(e.value)} dateFormat="dd/mm/yy"/>
          </div>
        </div>
        <div className="field text-right">
          <Button icon="pi pi-check" label="Xong" className="p-button-outlined p-button-sm" onClick={() => op.current.hide()}/>
        </div>
      </OverlayPanel>
    </Fragment>
  );
}
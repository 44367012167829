import moment from 'moment';

const timeZone = 'Asia/Ho_Chi_Minh';

export class FormatDisplay {

  static date(value: any, format?: string): string {
    if (value) {
      return moment(new Date(value).toLocaleString('en-US', {timeZone}), 'M/D/YYYY, HH:mm:ss A').format(format || 'DD/MM/YYYY');
    }
    return value;
  }

  static dateTime(value: any): string {
    if (value) {
      return moment(new Date(value).toLocaleString('en-US', {timeZone}), 'M/D/YYYY, HH:mm:ss A').format('DD/MM/YYYY HH:mm:ss');
    }
    return value;
  }

  static number(value: any): any {
    if (value) {
      return parseFloat(value).toLocaleString('vi-VN');
    }
    return value;
  }

}
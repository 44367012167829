import {Fragment, useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';

import {AppTopbar} from './AppTopbar';
import {AppBreadCrumb} from './AppBreadCrumb';

import {AuthService} from '../service/AuthService';

import {Lotteries} from './lotteries';
import {Report} from './report';
import {Users} from './users';

function App() {

  const [me, setMe] = useState(null);

  useEffect(() => {
    AuthService.getMe().then(data => {
      setMe(data);
    });
  }, []);

  return (
    <div className="layout-wrapper layout-overlay layout-theme-light">
      {me && <Fragment>
				<AppTopbar me={me}/>
				<AppBreadCrumb/>

				<div className="layout-main-container">
					<div className="layout-main">
						<Routes>
							<Route path="lotteries/*" element={<Lotteries me={me}/>}/>
							<Route path="report/*" element={<Report me={me}/>}/>
							<Route path="users" element={<Users/>}/>
						</Routes>
					</div>
				</div>
			</Fragment>}
    </div>
  );
}

export default App;

import {Service} from './Service';

export class AuthService extends Service {

  static entity: string = '';

  static login(username: string, password: string): Promise<any> {
    return this.axios.post(`${this.entity}/login`, {username, password}).then(res => res.data);
  }

  static changePassword(data: any): Promise<any> {
    return this.axios.post(`${this.entity}/change-password`, data).then(res => res.data);
  }

  static logout(): Promise<any> {
    return this.axios.get(`${this.entity}/logout`).then(res => res.data);
  }

  static getMe(): Promise<any> {
    return this.axios.get(`${this.entity}/me`).then(res => res.data);
  }

  static getDate(): Promise<any> {
    return this.axios.get(`${this.entity}/date`).then(res => res.data);
  }

}

import {Fragment, useEffect, useRef, useState} from 'react';

import {DataTable} from 'primereact/datatable';
import {DataTableSelectionModeType} from 'primereact/datatable';
import {Row} from 'primereact/row';
import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';

import {IamColumn, makeDynamicColumns} from './DataTableBasic';

const ActionMenu = (props) => {

  const menu = useRef(null);

  return (
    <Fragment>
      <Menu model={props.actionMenus} popup ref={menu}/>
      <Button icon="pi pi-ellipsis-h" onClick={(event) => menu.current.toggle(event)}
              title="Khác" className="p-button-rounded p-button-secondary p-button-outlined p-button-sm"/>
    </Fragment>
  );
}

interface DataTableProps {
  tableHeader?: any;
  dataKey?: string;
  columns: Array<IamColumn>;

  actionColumnWidth?: number;
  indexColumnWidth?: number;
  hideCurrentPageReport?: boolean;
  hideRowsPerPageDropdown?: boolean;

  requiredParams?: any;

  getList(params: any): Promise<any>;

  initActions?(items: Array<any>): void;

  rows?: number;
  selectionMode?: DataTableSelectionModeType;

  headerColumnGroups?: Array<any>;
}

export const useDataTable = (props: DataTableProps) => {

  const {tableHeader, dataKey, columns, actionColumnWidth, indexColumnWidth, hideCurrentPageReport, hideRowsPerPageDropdown, requiredParams, getList, initActions, rows, selectionMode, headerColumnGroups} = props;

  const emptyParams = {
    first: 0,
    rows: rows || 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {}
  };
  columns.forEach((column) => {
    if (column.matchMode) {
      emptyParams.filters[column.field] = {value: '', matchMode: column.matchMode};
    }
  });

  const [lazyParams, setLazyParams] = useState(emptyParams);
  const [items, setItems] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableState, setDataTableState] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (requiredParams === undefined || requiredParams) {
      loadLazyData();
    }
  }, [requiredParams, lazyParams, dataTableState]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshLazyData = () => {
    setDataTableState(Date.now);
  }

  const reloadLazyData = () => {
    setSelectedItems([]);
    setLazyParams(emptyParams);
    setDataTableState(Date.now);
  }

  const loadLazyData = () => {
    const _lazyParams = Object.assign({}, lazyParams);
    if (requiredParams) {
      _lazyParams.filters = Object.assign(Object.assign({}, _lazyParams.filters), requiredParams);
    }
    Object.keys(_lazyParams.filters).forEach(k => {
      if (_lazyParams.filters[k]?.value === '') {
        delete _lazyParams.filters[k];
      }
    });
    getList(JSON.stringify(_lazyParams)).then((response) => {

      if (initActions) {
        initActions(response.items);
      }

      setItems(response.items);
      setTotalRecords(response.totalRecords);
    });
  }

  const clearItems = () => {
    setItems([]);
    setTotalRecords(0);
    setSelectedItems(null);
  }

  const onPage = event => {
    setLazyParams(event);
  }

  const onSort = event => {
    setLazyParams(event);
  }

  const onFilter = event => {
    event['first'] = 0;
    setLazyParams(event);
  }

  const render = () => {

    const {filterDisplay, dynamicColumns} = makeDynamicColumns({columns});

    let selectionColumn;
    switch (selectionMode) {
      case 'radiobutton':
        selectionColumn = <Column selectionMode="single" style={{minWidth: '40px', maxWidth: '40px'}} className="justify-content-center"></Column>
        break;
      case 'checkbox':
        selectionColumn = <Column selectionMode="multiple" style={{minWidth: '40px', maxWidth: '40px'}} className="justify-content-center"/>;
        break;
    }

    let indexColumn;
    if (indexColumnWidth) {
      const indexColumnTemplate = (data, props) => {
        return props.rowIndex + 1;
      }
      indexColumn = <Column header="#" body={indexColumnTemplate} style={{minWidth: indexColumnWidth, maxWidth: indexColumnWidth}} className="justify-content-center"/>;
    }

    let actionColumn;
    if (actionColumnWidth) {
      const actionColumnTemplate = rowData => {
        let actions = '';
        if (rowData.actions) {
          actions = rowData.actions.map((action, index) => {
            return <Button key={index} icon={action.icon} onClick={action.command} disabled={action.disabled}
                           title={action.title} className={`p-button-rounded p-button-outlined p-button-sm mr-1 ${action.className}`}/>;
          });
        }
        let actionMenus;
        if (rowData.actionMenus) {
          actionMenus = <ActionMenu actionMenus={rowData.actionMenus}/>;
        }
        return (
          <Fragment>
            {actions}
            {actionMenus}
          </Fragment>
        );
      }
      actionColumn = <Column header="" className="table-actions" body={actionColumnTemplate} style={{maxWidth: actionColumnWidth}}/>;
    }

    let paginatorTemplate = [];
    if (!hideCurrentPageReport) {
      paginatorTemplate.push('CurrentPageReport');
    }
    paginatorTemplate.push('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink');
    if (!hideRowsPerPageDropdown) {
      paginatorTemplate.push('RowsPerPageDropdown');
    }

    let rowsPerPageOptions = [20, 50, 100, 200];
    if (rows && rows !== 20) {
      rowsPerPageOptions = [rows, 50, 100, 200];
    }

    let headerColumnGroup;
    if (headerColumnGroups && headerColumnGroups.length) {
      headerColumnGroup = (
        <ColumnGroup>
          <Row>
            {selectionColumn &&
							<Column style={{width: '40px', padding: 0, border: 'none'}}/>
            }
            {indexColumn &&
							<Column style={{width: indexColumnWidth, padding: 0, border: 'none'}}/>
            }
            {
              columns.map((column, index) => {
                const style: any = {padding: 0, border: 'none'};
                if (column.minWidth) {
                  style.minWidth = column.minWidth;
                } else {
                  style.width = column.width;
                }
                return <Column key={index} style={style}/>
              })
            }
            {actionColumnWidth &&
							<Column style={{width: actionColumnWidth, padding: 0, border: 'none'}}/>
            }
          </Row>
          {headerColumnGroups &&
						<Row>
              {
                headerColumnGroups.map((headerColumnGroup, index) => {
                  return <Column key={index} header={headerColumnGroup.header} colSpan={headerColumnGroup.colSpan}/>
                })
              }
						</Row>
          }
          <Row>
            {selectionColumn}
            {indexColumn}
            {
              columns.map((column, index) => {
                return <Column key={index} header={column.header} sortable field={column.field} style={column.style}/>
              })
            }
            {actionColumnWidth &&
							<Column header=""/>
            }
          </Row>
        </ColumnGroup>
      );
    }

    return (
      <div className="iam-data-table">
        <DataTable header={tableHeader} value={items} lazy filterDisplay={filterDisplay} dataKey={dataKey}
                   selectionMode={selectionMode || 'single'} selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)} selectionPageOnly
                   paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={rowsPerPageOptions} totalRecords={totalRecords} onPage={onPage}
                   paginatorTemplate={paginatorTemplate.join(' ')}
                   currentPageReportTemplate="{first} - {last} trong {totalRecords} bản ghi"
                   onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                   onFilter={onFilter} filters={lazyParams.filters}
                   showGridlines stripedRows size="small" resizableColumns columnResizeMode="expand" responsiveLayout="scroll"
                   scrollable scrollHeight="flex" headerColumnGroup={headerColumnGroup}>
          {selectionColumn}
          {indexColumn}
          {dynamicColumns}
          {actionColumn}
        </DataTable>
      </div>
    )
  }

  return {render, refreshLazyData, reloadLazyData, clearItems, selectedItems, setSelectedItems};
}

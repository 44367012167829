import {useState} from 'react';

import {classNames} from 'primereact/utils';

import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown, DropdownProps} from 'primereact/dropdown';
import {MultiSelect, MultiSelectProps} from 'primereact/multiselect';
import {TreeSelect, TreeSelectProps} from 'primereact/treeselect';
import {AutoComplete, AutoCompleteProps} from 'primereact/autocomplete';
import {InputNumber, InputNumberProps} from 'primereact/inputnumber';
import {Checkbox} from 'primereact/checkbox';
import {Calendar, CalendarProps} from 'primereact/calendar';
import {RadioButton} from 'primereact/radiobutton';

import {Period} from '../components/Period';
import IamEditor from '../components/IamEditor';

import {translate} from '../language';

type IamFieldType = ('' | 'Period' | 'AutoComplete' | 'Dropdown' | 'MultiSelect' | 'TreeSelect' | 'InputNumber' | 'Checkbox' | 'MultiCheckbox' | 'FileUploader' | 'Calendar' | 'RadioButton' | 'Password' | 'InputTextarea' | 'Editor' | 'email' | 'CKEditor' | 'file' | 'custom' | 'template' | 'hidden' | 'tax-code' | 'text' | 'color');

interface InputFileProps {
  accept?: string
  multiple?: boolean
}

interface InputTextProps {
  maxLength?: number
  keyfilter?: any
  type?: string
  placeholder?: string
}

interface RadioButtonProps {
  RadioButtons: {
    className?: string
    value: any
    label: string
  }[]
}

interface MultiCheckboxProps {
  Checkboxes: {
    className?: string
    value: any
    label: string
    disabled?: boolean
    disabledDependency?(item: any): boolean
  }[]
}

interface FileUploaderProps {
  render: any
}

export interface Field {
  field?: string
  header?: string
  type?: IamFieldType
  InputTextProps?: InputTextProps
  AutoCompleteProps?: AutoCompleteProps
  InputNumberProps?: InputNumberProps
  DropdownProps?: DropdownProps
  MultiSelectProps?: MultiSelectProps
  TreeSelectProps?: TreeSelectProps
  InputFileProps?: InputFileProps
  RadioButtonProps?: RadioButtonProps
  MultiCheckboxProps?: MultiCheckboxProps
  FileUploaderProps?: FileUploaderProps
  CalendarProps?: CalendarProps
  required?: boolean | 'onlyCreate'
  className?: string
  disabled?: boolean | 'onlyUpdate'
  uppercase?: boolean
  body?: any

  disabledDependency?(item: any): boolean

  displayDependency?(item: any): boolean

  labelClassName?: string
  inputClassName?: string

  template?(item: any): any
}

interface FormProps {
  fields: Array<Field>
  disabled?: boolean
  autoFocus?: boolean
  idUpdate?: any

  layout?: string
  formClassName?: string

  readOnlyInput?: boolean
}

export const useForm = (props: FormProps) => {

  const {fields, disabled, autoFocus, idUpdate, layout, formClassName, readOnlyInput} = props;

  const getEmptyFieldValue = (field: Field) => {
    let value;
    switch (field.type) {
      case 'InputNumber':
        value = null;
        break;
      case 'MultiCheckbox':
        value = [];
        break;
      default:
        value = '';
    }
    return value;
  }
  const emptyItem = {};
  fields.forEach((field) => {
    emptyItem[field.field] = getEmptyFieldValue(field);
  });
  const [item, setItem] = useState(emptyItem);
  const [validators, setValidators] = useState({});

  const reset = () => {
    setValidators({});
    setItem(emptyItem);
  }

  const setValue = data => {
    const newItem = {isUpdateValue: data.isUpdateValue};
    fields.forEach((field) => {
      let _value = data[field.field];
      if (_value === undefined || _value === null) {
        _value = getEmptyFieldValue(field);
      }
      newItem[field.field] = _value;
    });
    setItem(newItem);
  }

  const getRawValue = (): any => {
    return Object.assign(item, {});
  }

  const getValue = () => {
    const value: any = {};
    if (item && Object.keys(item)) {
      Object.keys(item).forEach((key) => {
        switch (typeof item[key]) {
          case 'string':
            if (item[key]) {
              value[key] = item[key].trim();
            }
            break;
          case 'object':
            let type;
            for (const field of fields) {
              if (field.field === key) {
                type = field.type;
              }
            }
            switch (type) {
              case 'Calendar':
                value[key] = formatCalendar(item[key]);
                break;
              default:
                value[key] = item[key];
            }
            break;
          default:
            value[key] = item[key];
        }
      });
    }
    return value;
  }

  const valid = () => {
    let _validators = {}, valid = true;

    for (const field of fields) {
      if (field.displayDependency && field.displayDependency(item) === false) {
        continue;
      }
      const fieldValidator = getFieldValidator(field, item[field.field]);
      _validators[field.field] = fieldValidator;
      if (fieldValidator) {
        valid = false;
      }
    }
    setValidators(_validators);

    return valid;
  }

  const validField = (field, fieldValue: any) => {
    const _validators = {...validators};
    _validators[field.field] = getFieldValidator(field, fieldValue);
    setValidators(_validators);
  }

  const getFieldValidator = (field, fieldValue: any) => {
    let error = '';
    switch (field.type) {
      case 'InputNumber':
        if ((field.required === true || (field.required === 'onlyCreate' && !idUpdate)) && isNaN(parseFloat(fieldValue))) {
          error = 'required';
        }
        break;
      case 'file':
        if ((field.required === true || (field.required === 'onlyCreate' && !idUpdate)) && !fieldValue) {
          error = 'required';
        }
        break;
      case 'FileUploader':
        break;
      default:
        fieldValue = `${fieldValue}`;
        if ((field.required === true || (field.required === 'onlyCreate' && !idUpdate)) && (!fieldValue || !fieldValue.trim())) {
          error = 'required';
        } else if ((field.required === true || (field.required === 'onlyCreate' && !idUpdate))
          && field.type === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue)) {
          error = 'pattern';
        }
    }
    return error;
  }

  const onInputChange = (field, value: any) => {
    const _item: any = {...item};
    if (field.uppercase && value) {
      value = value.toUpperCase();
    }
    _item[field.field] = value;

    _item.isUpdateValue = false;
    setItem(_item);
    validField(field, value);
  }

  const onMultiCheckboxChange = (field, e: any) => {
    const selectedItems = [...item[field.field]];
    if (e.checked)
      selectedItems.push(e.value);
    else
      selectedItems.splice(selectedItems.indexOf(e.value), 1);

    onInputChange(field, selectedItems);
  }

  const render = () => {
    const dynamicFields = fields.map((field, index) => {
      if (field.displayDependency && field.displayDependency(item) === false) {
        return null;
      } else if (field.type === 'hidden') {
        return null;
      } else if (field.type === 'custom') {
        if (layout === 'Horizontal') {
          return <div key={index} className={`col-12 ${field.className}`}>{field.body}</div>
        } else {
          return <div key={index} className={`field col-12 ${field.className}`}>{field.body}</div>
        }
      } else if (field.type === 'template') {
        return <div key={index} className={`field col-12 ${field.className}`}>{field.template(item)}</div>;
      } else {
        let required = null;
        if ((field.required === true || (field.required === 'onlyCreate' && !idUpdate))) {
          required = <span className="required">*</span>;
        }
        let label;
        if (field.header) {
          let labelClassName;
          if (field.labelClassName) {
            labelClassName = `col-12 mb-2 ${field.labelClassName} md:mb-0`;
          }
          label = <label htmlFor={field.field} className={labelClassName}>{translate(field.header)}{required}</label>;
        }

        let input;

        const className = classNames({'p-invalid': validators[field.field]});
        const fieldDisabled = disabled || field.disabled === true || (field.disabled === 'onlyUpdate' && idUpdate) || (field.disabledDependency && field.disabledDependency(item));
        const value = item[field.field];
        const _autoFocus = autoFocus && index === 0;

        switch (field.type) {
          case 'AutoComplete':
            input = <AutoComplete id={field.field} value={value} onChange={e => onInputChange(field, e.value)} autoFocus={_autoFocus}
                                  className={className} disabled={!!fieldDisabled} {...field.AutoCompleteProps} readOnly={readOnlyInput}/>;
            break;
          case 'Dropdown':
            input = <Dropdown id={field.field} value={value} onChange={e => onInputChange(field, e.value)} autoFocus={_autoFocus}
                              className={className} disabled={!!fieldDisabled} {...field.DropdownProps} readOnly={readOnlyInput}/>;
            break;
          case 'MultiSelect':
            input = <MultiSelect id={field.field} value={value} onChange={e => onInputChange(field, e.value)} autoFocus={_autoFocus}
                                 className={className} disabled={!!fieldDisabled} {...field.MultiSelectProps} readOnly={readOnlyInput}/>
            break;
          case 'TreeSelect':
            input = <TreeSelect id={field.field} value={value} onChange={e => onInputChange(field, e.value)} autoFocus={_autoFocus}
                                className={className} disabled={!!fieldDisabled} {...field.TreeSelectProps} readOnly={readOnlyInput}/>
            break;
          case 'InputNumber':
            input = <InputNumber id={field.field} value={value} onChange={e => onInputChange(field, e.value)} autoFocus={_autoFocus}
                                 className={className} disabled={!!fieldDisabled} {...field.InputNumberProps} readOnly={readOnlyInput}/>
            break;
          case 'Checkbox':
            input = <Checkbox inputId={field.field} checked={value} onChange={e => onInputChange(field, e.checked)} disabled={!!fieldDisabled} readOnly={readOnlyInput}/>
            break;
          case 'Calendar':
            input = <Calendar id={field.field} value={value} onChange={e => onInputChange(field, e.value)}
                              className={className} dateFormat="dd/mm/yy" disabled={!!fieldDisabled || readOnlyInput} {...field.CalendarProps}/>
            break;
          case 'FileUploader':
            input = field.FileUploaderProps.render();
            break;
          case 'RadioButton':
          case 'tax-code':
          case 'text':
          case 'MultiCheckbox':
            break;
          case 'file':
            input = <input id={field.field} type="file" onChange={e => onInputChange(field, e.target.files)} autoFocus={_autoFocus}
                           className={className} disabled={!!fieldDisabled} {...field.InputFileProps} readOnly={readOnlyInput}/>
            break;
          case 'InputTextarea':
            input = <InputTextarea id={field.field} value={value} onChange={e => onInputChange(field, e.target.value)} autoFocus={_autoFocus}
                                   className={className} disabled={!!fieldDisabled} readOnly={readOnlyInput}/>
            break;
          case 'Editor':
            input = <IamEditor id={field.field} value={value} onChange={e => onInputChange(field, e.value)} autoFocus={_autoFocus}
                               className={className} disabled={!!fieldDisabled} readOnly={readOnlyInput}/>
            break;
          case 'Password':
            input = <Password id={field.field} value={value} onChange={e => onInputChange(field, e.target.value)} toggleMask autoFocus={_autoFocus}
                              className={className} disabled={!!fieldDisabled} feedback={false} autoComplete="off" readOnly={readOnlyInput}/>
            break;
          case 'Period':
            input = <Period value={value} onChange={e => onInputChange(field, e.value)}/>
            break;
          default:
            input = <InputText id={field.field} type="text" value={value} onChange={e => onInputChange(field, e.target.value)} autoFocus={_autoFocus}
                               className={className} disabled={!!fieldDisabled} {...field.InputTextProps} readOnly={readOnlyInput} autoComplete="one-time-code"/>
        }

        let dynamicField;
        switch (layout) {
          case 'Horizontal':
            switch (field.type) {
              case 'RadioButton':
                dynamicField = <div key={index} className="col-12">
                  <div className={`grid ${field.className}`}>
                    {
                      field.RadioButtonProps.RadioButtons.map((radioButton, index) => {
                        return (
                          <div key={index} className={`col-12 ${radioButton.className}`}>
                            <div className="field-radiobutton">
                              <RadioButton inputId={field.field + radioButton.value} name={field.field} value={radioButton.value} onChange={e => onInputChange(field, e.value)} checked={value === radioButton.value}/>
                              <label htmlFor={field.field + radioButton.value}>{radioButton.label}</label>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                break;
              case 'MultiCheckbox':
                dynamicField = <div key={index} className={`col-12 ${field.className}`}>
                  {
                    field.MultiCheckboxProps.Checkboxes.map((checkbox, index) => {
                      let _disabled = checkbox.disabled;
                      if (checkbox.disabledDependency) {
                        _disabled = checkbox.disabledDependency(item);
                      }
                      return (
                        <div key={index} className="field-checkbox">
                          <Checkbox inputId={field.field + checkbox.value} name={field.field} value={checkbox.value} disabled={_disabled === true}
                                    onChange={e => onMultiCheckboxChange(field, e)} checked={value.indexOf(checkbox.value) !== -1}/>
                          <label htmlFor={field.field + checkbox.value}>{checkbox.label}</label>
                        </div>
                      )
                    })
                  }
                </div>
                break;
              case 'tax-code':
                let _value;
                if (value) {
                  const codes = value.replace('-', '');
                  _value = codes.split('').map((code, index) => {
                    return <div key={index}>{code}</div>
                  });
                }
                dynamicField = <div key={index} className={`col-12 ${field.className}`}>
                  <div className="field grid">
                    {label}
                    <div className={`col-12 tax-code ${field.inputClassName}`}>
                      {_value}
                    </div>
                  </div>
                </div>
                break;
              case 'text':
                dynamicField = <div key={index} className={`col-12 ${field.className}`}>
                  <div className="field grid">
                    {label}
                    <div className={`col-12 ${field.inputClassName}`}>
                      {value}
                    </div>
                  </div>
                </div>
                break;
              default:
                dynamicField = <div key={index} className={`col-12 ${field.className}`}>
                  <div className="field grid">
                    {label}
                    <div className={`col-12 ${field.inputClassName}`}>
                      {input}
                      {(validators[field.field] === 'required') && <small className="p-error block">{translate(field.header)} không được bỏ trống.</small>}
                      {(validators[field.field] === 'pattern') && <small className="p-error block">{translate(field.header)} không hợp lệ.</small>}
                    </div>
                  </div>
                </div>
            }

            break;
          default:
            switch (field.type) {
              case 'Checkbox':
                dynamicField = <div key={index} className={`field col-12 ${field.className}`}>
                  <div className="field-checkbox">
                    {input}
                    {label}
                  </div>
                </div>
                break;
              case 'tax-code':
                let _value;
                if (value) {
                  const codes = value.replace('-', '');
                  _value = codes.split('').map((code, index) => {
                    return <div key={index}>{code}</div>
                  });
                }
                dynamicField = <div key={index} className={`field col-12 ${field.className}`}>
                  {label}
                  <div className="tax-code">
                    {_value}
                  </div>
                </div>
                break;
              default:
                dynamicField = <div key={index} className={`field col-12 ${field.className}`}>
                  {label}
                  {input}
                  {(validators[field.field] === 'required') && <small className="p-error block">{translate(field.header)} không được bỏ trống.</small>}
                  {(validators[field.field] === 'pattern') && <small className="p-error block">{translate(field.header)} không hợp lệ.</small>}
                </div>
            }
        }
        return dynamicField;
      }
    });

    return (
      <div className={`p-fluid formgrid grid ${formClassName}`}>
        {dynamicFields}
      </div>
    );
  }

  return {render, getValue, getRawValue, reset, setValue, valid};

}

export const formatCalendar = value => {
  if (value) {
    return new Date(value).getTime();
  }
  return value;
}
import {useEffect, useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';

import {Menubar} from 'primereact/menubar';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';

import {AuthService} from '../service/AuthService';

import {ToastService} from '../AppToast';
import {BreadcrumbsService} from './AppBreadCrumb';

export const AppTopbar = ({me}) => {

  const location = useLocation();

  const [submitting, setSubmitting] = useState(false);

  const [displayChangePassword, setDisplayChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerify, setNewPasswordVerify] = useState('');

  const template = (item: any, options: any) => {
    if (item.target) {
      return (
        <NavLink to={item.target} className={options.className}>
          <span className={options.iconClassName}/>
          <span className={options.labelClassName}>{item.label}</span>
        </NavLink>
      );
    } else {
      return (
        <a href="/" className={options.className} onClick={options.onClick}>
          <span className={options.iconClassName}/>
          <span className={options.labelClassName}>{item.label}</span>
        </a>
      );
    }
  };

  const logout = () => {
    AuthService.logout().then(() => {
      window.location.hash = '/login';
    });
  }

  const menus: Array<any> = [
    {label: 'Tổng quan', icon: 'pi pi-fw pi-home', target: '/', template},
    {label: 'Chương trình quay số', icon: 'pi pi-fw pi-slack', target: '/lotteries', template},
    {
      label: 'Báo cáo', icon: 'pi pi-fw pi-chart-line', className: 'menu-w-16rem', items: [
        {label: 'Báo cáo kết quả', icon: 'pi pi-fw pi-circle', target: '/report/lottery-results', template},
        {label: 'Báo cáo khách hàng', icon: 'pi pi-fw pi-circle', target: '/report/customers', template}
      ]
    }
  ];
  if (me.role_type?.includes('admin')) {
    menus.push(...[
      {label: 'Tài khoản', icon: 'pi pi-fw pi-key', target: '/users', template}
    ]);
  }
  menus.push(...[
    {
      label: 'Tài khoản', icon: 'pi pi-fw pi-user', items: [
        {label: 'Đổi mật khẩu', icon: 'pi pi-fw pi-lock', command: () => setDisplayChangePassword(true)},
        {separator: true},
        {label: 'Đăng xuất', icon: 'pi pi-fw pi-sign-out', command: logout}
      ]
    }
  ]);

  useEffect(() => {
    menus.forEach((menu: any) => {
      menu.recursiveLabels = [];
      setMenuDescriptionRecursive(menu);
    });
    getMenuDescription();
  }, [location, menus]); // eslint-disable-line react-hooks/exhaustive-deps

  const setMenuDescriptionRecursive = (menu) => {
    menu.recursiveLabels.push(menu);
    if (menu.items) {
      menu.items.forEach(item => {
        item.recursiveLabels = [...menu.recursiveLabels];
        setMenuDescriptionRecursive(item);
      });
    }
  }

  const getMenuDescriptionRecursive = (pathname, menu) => {
    let recursiveLabels;
    if (pathname === menu.target) {
      recursiveLabels = menu.recursiveLabels;
    } else if (pathname === `${menu.target}/create` || pathname.includes(`${menu.target}/create/`)) {
      recursiveLabels = menu.recursiveLabels;
      recursiveLabels.push({label: 'Tạo mới'});
    } else if (pathname.includes(`${menu.target}/detail/`)) {
      recursiveLabels = menu.recursiveLabels;
      recursiveLabels.push({label: 'Chi tiết'});
    } else if (pathname.includes(`${menu.target}/update/`)) {
      recursiveLabels = menu.recursiveLabels;
      recursiveLabels.push({label: 'Chỉnh sửa'});
    }

    if (!recursiveLabels && menu.items) {
      for (const item of menu.items) {
        recursiveLabels = getMenuDescriptionRecursive(pathname, item);
        if (recursiveLabels) {
          break;
        }
      }
    }
    return recursiveLabels;
  }

  const getMenuDescription = () => {
    let recursiveLabels;
    for (const menu of menus) {
      recursiveLabels = getMenuDescriptionRecursive(location.pathname, menu);
      if (recursiveLabels) {
        break;
      }
    }
    if (recursiveLabels) {
      const breadcrumbs = [];
      recursiveLabels.forEach(({label, target}) => {
        const breadcrumb: any = {label};
        if (target) {
          breadcrumb.command = () => {
            window.location.hash = target;
          }
        }
        breadcrumbs.push(breadcrumb);
      });
      BreadcrumbsService.setBreadcrumbs(breadcrumbs);
    }
  }

  const changePassword = event => {
    if (!submitting) {
      setSubmitting(true);

      if (newPassword === newPasswordVerify) {
        AuthService.changePassword({oldPassword, newPassword}).then(() => {
          setDisplayChangePassword(false);
          ToastService.success();
        }).finally(() => {
          setSubmitting(false);
        });
      } else {
        ToastService.error('Mật khẩu nhập lại không chính xác');
      }
    }
    event.preventDefault();
  }

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img src="/coop-smile/CoopSmile-Logo.webp" alt="CoopSmile" style={{height: 'auto'}}/>
      </Link>

      <Menubar model={menus} className="app-menu layout-topbar-menu lg:flex origin-top"/>

      <Dialog header="Đổi mật khẩu" visible={displayChangePassword} style={{width: '500px'}} position="top" closeOnEscape={false} onHide={() => setDisplayChangePassword(false)}>
        <form onSubmit={changePassword}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <label htmlFor="oldPassword">Mật khẩu hiện tại</label>
              <Password id="oldPassword" value={oldPassword} onChange={e => setOldPassword(e.target.value)} toggleMask feedback={false} required/>
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="newPassword">Mật khẩu mới</label>
              <Password id="newPassword" value={newPassword} onChange={e => setNewPassword(e.target.value)} toggleMask required/>
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="newPasswordVerify">Xác nhận mật khẩu mới</label>
              <Password id="newPasswordVerify" value={newPasswordVerify} onChange={e => setNewPasswordVerify(e.target.value)} toggleMask feedback={false} required/>
            </div>
          </div>

          <div className="flex justify-content-end mt-2">
            <Button label="Đổi mật khẩu" icon="pi pi-check" className="p-button-sm" loading={submitting}/>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
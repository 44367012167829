import {useEffect, useState} from 'react';

import {AutoComplete} from 'primereact/autocomplete';

import {LotteriesService} from '../../lotteries/LotteriesService';

import {ActionsService} from '../../AppBreadCrumb';

import {WinnerTable} from '../../lotteries/item/Winners';
import {ToastService} from '../../../AppToast';

export const LotteryResults = ({me}) => {

  const [marketingEvent, setMarketingEvent] = useState(null);
  const [filteredMarketingEvents, setFilteredMarketingEvents] = useState(null);

  const [winners, setWinners] = useState([]);

  useEffect(() => {
    if (marketingEvent && marketingEvent.value) {
      LotteriesService.getWinners(marketingEvent.value).then(data => {
        setWinners(data);
      });
    }

    if (me.role_type?.includes('admin') || me.role_type?.includes('export')) {
      ActionsService.setActions([
        {label: 'Kết xuất dữ liệu', icon: 'pi pi-fw pi-download', className: 'p-action-primary', command: exportExcel}
      ]);
    }
    return () => {
      ActionsService.setActions(null);
    }
  }, [marketingEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchMarketingEvent = event => {
    const _event = {
      filters: {
        event_name: {value: event.query, matchMode: 'contains'}
      }
    };
    LotteriesService.getList(JSON.stringify(_event)).then(data => {
      setFilteredMarketingEvents(data.items.map(item => {
        return {value: item.marketing_event_id, label: item.event_name}
      }));
    });
  }

  const exportExcel = () => {
    if (marketingEvent && marketingEvent.value) {
      window.open(`${LotteriesService.baseURL}/report/${marketingEvent.value}/lottery-results/excel`)
    } else {
      ToastService.error('Bạn chưa chọn chương trình');
    }
  }

  return (
    <div className="card">
      <div className="p-fluid grid">
        <div className="col-6">
          <AutoComplete value={marketingEvent} onChange={e => setMarketingEvent(e.value)}
                        field="label" suggestions={filteredMarketingEvents} completeMethod={searchMarketingEvent} dropdown={true}/>
        </div>
      </div>
      <WinnerTable winners={winners}/>
    </div>
  );
}
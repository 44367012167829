import {useState} from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {Checkbox} from 'primereact/checkbox';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {Calendar} from 'primereact/calendar';

import {classNames} from 'primereact/utils';

import {FormatDisplay} from '../format';
import {translate} from '../language';

interface DataTableBasicProps {
  header?: any;
  dataKey?: string;
  columns: Array<IamColumn>;
  items: Array<any>;

  indexColumnWidth?: number;
}

export const useDataTableBasic = (props: DataTableBasicProps) => {

  const {header, dataKey, columns, items, indexColumnWidth} = props;

  const [selectedItem, setSelectedItem] = useState(null);

  const render = () => {
    let tableHeader;
    if (header) {
      tableHeader = (
        <div className="table-header">
          {translate(header)}
        </div>
      );
    }

    let indexColumn;
    if (indexColumnWidth) {
      const indexColumnTemplate = (data, props) => {
        return props.rowIndex + 1;
      }
      indexColumn = <Column header="#" body={indexColumnTemplate} style={{minWidth: indexColumnWidth, maxWidth: indexColumnWidth}} className="justify-content-center"/>;
    }

    const {dynamicColumns} = makeDynamicColumns({columns});

    return (
      <DataTable header={tableHeader} value={items} dataKey={dataKey}
                 selectionMode="single" selection={selectedItem} onSelectionChange={e => setSelectedItem(e.value)}
                 showGridlines stripedRows size="small" resizableColumns columnResizeMode="fit" responsiveLayout="scroll"
                 scrollable scrollHeight="flex">
        {indexColumn}
        {dynamicColumns}
      </DataTable>
    );
  }

  return {render, selectedItem, setSelectedItem};
}

type MatchMode = 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'custom';
type DataType = ('' | 'date' | 'date-time' | 'number' | 'boolean' | 'checkbox' | 'hot-checkbox' | 'fromFilterOptions' | 'custom' | 'dropdown');
type FilterType = ('' | 'date' | 'number' | 'dropdown' | 'boolean');

export interface IamColumn {
  field: string;
  header: string;
  width?: any;
  minWidth?: any;
  matchMode?: MatchMode;
  filterType?: FilterType;
  filterOptions?: Array<any>;
  dataType?: DataType;
  dataOptions?: Array<any>;

  customCell?(rowData: any): any;

  style?: any;
  sortable?: boolean;
  editable?: boolean;
  invisible?: boolean;

  bodyConfig?: any;

  title?: string;
}

interface DynamicColumnsParams {
  columns: Array<IamColumn>;
}

export const makeDynamicColumns = (params: DynamicColumnsParams) => {

  const {columns} = params;

  const _columns = [];
  for (const column of columns) {
    if (column.invisible) {
      continue;
    }
    _columns.push(column);
  }
  let filterDisplay;
  const dynamicColumns = _columns.map((col) => {
    let filterElement = null;
    switch (col.filterType) {
      case 'dropdown':
        filterElement = (options) => {
          return <Dropdown value={options.value} options={col.filterOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="-" className="p-column-filter" showClear/>;
        }
        break;
      case 'boolean':
        filterElement = (options) => {
          return <div style={{textAlign: 'center'}}>
            <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)}/>
          </div>
        }
        break;
      case 'number':
        filterElement = (options) => {
          if (!options.value) {
            options.value = null;
          }
          return <InputNumber value={options.value} onChange={e => options.filterApplyCallback(e.value)}/>;
        }
        break;
      case 'date':
        filterElement = (options) => {
          return <Calendar value={options.value} onChange={e => {
            let _value: any = e.value;
            if (_value) {
              _value = FormatDisplay.date(_value, 'YYYY-MM-DD');
            }
            options.filterApplyCallback(_value, options.index)
          }} dateFormat="dd/mm/yy"/>;
        }
        break;
    }

    let body;
    switch (col.dataType) {
      case 'date':
        body = rowData => {
          if (rowData[col.field]) {
            return FormatDisplay.date(rowData[col.field]);
          } else {
            return '';
          }
        }
        break;
      case 'date-time':
        body = rowData => {
          if (rowData[col.field]) {
            return FormatDisplay.dateTime(rowData[col.field]);
          } else {
            return '';
          }
        }
        break;
      case 'number':
        body = rowData => {
          if (typeof rowData[col.field] === 'number') {
            if (col.bodyConfig) {
              return Intl.NumberFormat('vi-VN', col.bodyConfig).format(rowData[col.field]);
            } else {
              return rowData[col.field].toLocaleString('vi-VN');
            }
          } else {
            return rowData[col.field];
          }
        }
        col.style = col.style ? Object.assign({justifyContent: 'end'}, col.style) : {justifyContent: 'end'};
        break;
      case 'boolean':
        body = rowData => {
          return <i title={rowData[col.title]} className={classNames('pi', {'true-icon pi-check-circle': rowData[col.field], 'false-icon pi-times-circle': !rowData[col.field]})}/>
        }
        col.style = col.style ? Object.assign(col.style, {justifyContent: 'center'}) : {justifyContent: 'center'};
        break;
      case 'checkbox':
        body = rowData => {
          return <Checkbox checked={rowData[col.field]} readOnly/>
        }
        col.style = col.style ? Object.assign(col.style, {justifyContent: 'center'}) : {justifyContent: 'center'};
        break;
      case 'fromFilterOptions':
        body = rowData => {
          if (rowData[col.field] !== null && rowData[col.field] !== undefined && rowData[col.field] !== '') {
            if (col.filterOptions) {
              let value = '';
              col.filterOptions.forEach((option: any) => {
                if (rowData[col.field] === option.value) {
                  value = option.label;
                }
              });
              return value;
            }
            return '';
          } else {
            return '';
          }
        }
        break;
      case 'dropdown':
        body = rowData => {
          if (rowData[col.field]) {
            if (col.dataOptions) {
              let value = '';
              col.dataOptions.forEach((option: any) => {
                if (rowData[col.field] === option.value) {
                  value = option.label;
                }
              });
              return value;
            }
            return '';
          } else {
            return '';
          }
        }
        break;
      case 'custom':
        body = rowData => {
          return col.customCell(rowData);
        }
        break;
      default:
        body = rowData => {
          return <div title={rowData[col.field]}>{rowData[col.field]}</div>;
        }
    }
    if (col.matchMode) {
      filterDisplay = 'row';
    }

    const style: any = col.style || {};
    if (typeof col.width === 'number') {
      style.minWidth = col.width;
      style.maxWidth = col.width;
    } else if (typeof col.minWidth === 'number') {
      style.minWidth = col.minWidth;
    }

    return <Column key={col.field} field={col.field} header={translate(col.header)} style={style} body={body}
                   sortable={col.sortable !== false} filter={!!col.matchMode} showFilterMenu={false} showClearButton={false} filterElement={filterElement}
                   headerStyle={{justifyContent: 'center'}}/>;
  });
  return {filterDisplay, dynamicColumns};
}
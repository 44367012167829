import {EntityService} from '../../../service/EntityService';

export class CustomersService extends EntityService {

  static entity = 'report/customers';

  static sendSms(id: string): Promise<any> {
    return this.axios.put(`${this.entity}/${id}/send-sms`).then(res => res.data);
  }

}

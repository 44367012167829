import {useEffect, useState} from 'react';

import {ConfirmDialog, confirmDialog, ConfirmDialogProps} from 'primereact/confirmdialog';

import {BehaviorSubject} from 'rxjs';

const props = new BehaviorSubject(null);
export const ConfirmDialogService = {
  setProps: (data: ConfirmDialogProps) => props.next(data),
  getProps: () => props.asObservable(),
  confirm: (header: string, message: string, accept: () => void) => {
    props.next({
      message,
      header,
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Đồng ý',
      rejectLabel: 'Không',
      accept
    })
  },
  confirmCancel: (accept: () => void) => {
    props.next({
      message: 'Bạn chắc chắn muốn huỷ bản ghi này?',
      header: 'Xác nhận huỷ',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Huỷ',
      rejectLabel: 'Không',
      accept
    })
  },
  confirmDelete: (accept: () => void) => {
    props.next({
      message: 'Bạn chắc chắn muốn xoá bản ghi này?',
      header: 'Xác nhận xoá dữ liệu',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Xoá',
      rejectLabel: 'Không',
      accept
    })
  },
  showError: (errorCode: string, errors: string) => {
    props.next({
      message: errors,
      header: `Có lỗi xảy ra [CODE ${errorCode}]`,
      icon: 'pi pi-times-circle text-orange-400',
      acceptLabel: 'Đóng',
      rejectClassName: 'hidden'
    })
  },
  showInvalid: (message: string) => {
    props.next({
      message,
      header: `Có lỗi xảy ra`,
      icon: 'pi pi-times-circle text-orange-400',
      acceptLabel: 'Đóng',
      rejectClassName: 'hidden'
    })
  }
};

export const AppConfirmDialog = () => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    ConfirmDialogService.getProps().subscribe((props) => {
      if (props) {
        confirmDialog(props);
      }
    });
  }, []);

  return <ConfirmDialog visible={visible} onHide={() => setVisible(false)}/>
}
